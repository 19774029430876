import * as yup from "yup";

// import currencyjs from "currency.js";

export function formatCurrencyToNumber(string) {
  return string.toString().replace(/,/g, "");
}

export function formatNumber(n) {
  return n
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToCurrency(number = "", decimalPlace = false) {
  let value = number.toString();
  const decimalPosition = value.indexOf(".");
  if (decimalPosition >= 0) {
    let leftSide = formatNumber(value.substring(0, decimalPosition));
    let rightSide = formatNumber(value.substring(decimalPosition));
    if (decimalPlace) {
      rightSide += "00";
    }

    rightSide = rightSide.substring(0, 2);
    value = leftSide + "." + rightSide;
  } else {
    value = formatNumber(value);
    if (decimalPlace) {
      value += ".00";
    }
  }
  return value;
}

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export const isObjectEmpty = (obj) => obj && Object.keys(obj).length === 0;

export function deepMerge(target, source) {
  if (Array.isArray(target) && Array.isArray(source)) {
    const newTarget = [...target];
    for (const key in source) {
      if (typeof source[key] === "object") {
        newTarget[key] = deepMerge(newTarget[key] || {}, source[key]);
      } else {
        newTarget[key] = source[key] || newTarget[key];
      }
    }
  } else if (isObject(target) && isObject(source)) {
    const newTarget = { ...target };
    for (const key in source) {
      if (isObject(source[key])) {
        newTarget[key] = deepMerge(newTarget[key] || {}, source[key]);
      } else {
        newTarget[key] = source[key] || newTarget[key];
      }
    }
    return newTarget;
  }
  return undefined;
}

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    /* eslint-disable no-self-compare */
    return x !== x && y !== y;
  }
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

/**
 * @template C
 * @param {C} callback
 * @param {number} wait
 * @returns {C & {flush: Function, cancel: Function}}
 */
export function debounce(callback, wait = 0) {
  let debounceTimer;
  let triggerArgs;
  let triggerThis;

  function trigger(...arg) {
    triggerArgs = arg;
    triggerThis = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      callback.apply(triggerThis, triggerArgs);
    }, wait);
  }

  trigger.cancel = () => clearTimeout(debounceTimer);
  trigger.flush = () => {
    clearTimeout(debounceTimer);
    callback.apply(triggerThis, triggerArgs);
  };

  return trigger;
}

export function throttle(callback, wait = 0) {
  let throttleTimer;
  let triggerArgs;
  let triggerThis;
  function trigger() {
    triggerArgs = arguments;
    triggerThis = this;
    if (throttleTimer) return;
    throttleTimer = true;
    setTimeout(() => {
      callback.apply(triggerThis, triggerArgs);
      throttleTimer = false;
    }, wait);
  }

  trigger.cancel = () => clearTimeout(throttleTimer);
  trigger.flush = () => {
    clearTimeout(throttleTimer);
    callback.apply(triggerThis, triggerArgs);
  };

  return trigger;
}

export function objectToFormData(data) {
  const fd = new FormData();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const arrData of data[key]) {
        fd.append(key, arrData);
      }
    } else {
      fd.set(key, data[key]);
    }
  }
  return fd;
}

/**
 *
 * @param {Date | string | number} from
 * @param {Date | string | number} to
 */
export function getCountdown(from, to) {
  const oneSecondInMilli = 1000,
    oneMinuteInMilli = oneSecondInMilli * 60,
    oneHourInMilli = oneMinuteInMilli * 60,
    oneDayInMilli = oneHourInMilli * 24;

  const _from = new Date(from),
    _to = new Date(to),
    _fromTime = _from.getTime(),
    _toTime = _to.getTime(),
    distance = _toTime - _fromTime;

  const days = Math.floor(distance / oneDayInMilli),
    hours = Math.floor((distance % oneDayInMilli) / oneHourInMilli),
    minutes = Math.floor((distance % oneHourInMilli) / oneMinuteInMilli),
    seconds = Math.floor((distance % oneMinuteInMilli) / oneSecondInMilli);

  return { days, hours, minutes, seconds };
}

/**
 * @template {{[x: string]: any}} T
 * @param {URLSearchParams} searchParams
 * @param {T} params
 * @returns {T}
 */
export function urlSearchParamsExtractor(searchParams, params = {}) {
  if (searchParams && params) {
    const result = {};
    for (const key in params) {
      result[key] = searchParams.get(key) || params[key];
    }
    return result;
  }
  return params;
}
export function getTextFieldFormikProps(formik, key, debounce = false) {
  const fieldProps = formik.getFieldProps(key);
  return {
    id: key,
    name: key,
    value: fieldProps?.value || "",
    error: !!formik.touched[key] && !!formik.errors[key],
    helperText: !!formik.touched && formik.errors[key],
    ...fieldProps,
  };
}

export function yupFileSchema(message) {
  return yup.mixed().test(
    "isFile",
    // eslint-disable-next-line no-template-curly-in-string
    message || "${path} not a file",
    (value) =>
      (value && value instanceof File) ||
      /^(file|passports)\/{1}.+\.{1}.+/.test(value || "")
    // (value && value.includes("res.cloudinary.com"))
  );
}

// export const calculatePayment = (value) => {
//   let percentageValue = 0;
//   if (value >= 0) {
//     const onePercent = currencyjs(value).divide(100);
//     if (value < 1_000_000_000) {
//       percentageValue =
//         onePercent.multiply(0.1).value > 250_000 ? 250_000 : value;
//     } else if (value >= 1_000_000_000 || value <= 500_000_000_000) {
//       percentageValue =
//         onePercent.multiply(0.4).value > 2_000_000 ? 2_000_000 : value;
//     } else if (value > 500_000_000_000) {
//       percentageValue = 5_000_000;
//     }
//     return percentageValue;
//   }
// };

export const getFormatedDate = (dateString) => {
  const date = new Date(dateString),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
};

export const calculateAnnualTurnOver = (amount) =>
  amount < 50_000_000
    ? 5_000
    : amount >= 50_000_000 && amount < 500_000_000
    ? 20_000
    : amount >= 500_000_000 && amount < 1_000_000_000
    ? 50_000
    : amount >= 1_000_000_000 && amount < 10_000_000_000
    ? 100_000
    : 1_000_000;

// export const calculateMarketCapitalization = (value) => {
//   let amountToBePaid = 0;
//   if (value >= 0) {
//     let percentageAmount;
//     if (value <= 1_000_000_000) {
//       percentageAmount = currencyjs(value).multiply(0.001).value;
//       amountToBePaid = percentageAmount > 250_000 ? 250_000 : percentageAmount;
//     } else if (value >= 1_000_000_000 && value <= 500_000_000_000) {
//       percentageAmount = currencyjs(value).multiply(0.0004).value;
//       amountToBePaid =
//         percentageAmount > 2_000_000 ? 2_000_000 : percentageAmount;
//     } else if (value > 500_000_000_000) {
//       amountToBePaid = 5_000_000;
//     }

//     return amountToBePaid;
//   }
// };

export const calculateUpgradeFee = (currentProductCode, selectProductCode) => {
  const each = 25000;
  let payableAmount = 0;
  const splittedProductCodes = selectProductCode.product_code
    ?.toString()
    ?.split(",");
  const splittedCurrent = currentProductCode?.product_code
    ?.toString()
    ?.split(",");
  // const splittedLength = splittedProductCodes.length;

  const filteredResult = splittedProductCodes?.filter((item) =>
    splittedCurrent?.includes(item)
  );
  const diff = splittedProductCodes.length - filteredResult.length;
  if (diff >= 1) {
    payableAmount = diff * each;
  } else {
    payableAmount = 5000;
  }
  return { payableAmount, diff };
};

export function isPlainObject(value) {
  return value?.constructor === Object;
}

export function normalizeArray(
  array,
  options = { getKey: ({ id }) => id, getValue: (value) => value }
) {
  const { getKey = ({ id }) => id, getValue = (value) => value } = options;
  return array?.reduce((acc, curr) => {
    const key = getKey(curr);
    acc[key] = getValue(curr, acc[key]);
    return acc;
  }, {});
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatToAmount(amount, symbol = true) {
  return new Intl.NumberFormat("en-NG", {
    ...(symbol ? { style: "currency" } : {}),
    currency: "NGN",
    currencyDisplay: "code",
  }).format(amount || 0);
}

const maxLength = 200;

export function imageFileValidator(file) {
  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `File Name size  ${maxLength} too long`,
    };
  }
  return null;
}

export const isValidUrl = (url) => {
  const urlRegex =
    /^(?:https?):\/\/(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d{1,5})?(?:\/[\w/-]*)?(?:\?[\w-=&%]*)?(?:#[\w-]*)?$/i;
  return urlRegex.test(url);
};
